<template>
  <ModalFormGeneric :id="COMPNAME" :title="$t('res.tqr')" :btnText="$t('btn.x')" btnIcon="x"
      @submit="onSubmit"
      @reset="onReset"
      :ref="COMPNAME" hidePic>
    <QrShow :id="FORMNAME" :data="uuid.uuid4" :text="uuidShort" class="mb-3" />
  </ModalFormGeneric>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';

export default {
  data() {
    return {
      COMPNAME: 'qrShowWindow',
      FORMNAME: 'qrs',
    }
  },
  computed: {
    uuid() {
      return this.$store.state.oResDetails ?? { uuid4: 'notloaded' };
    },
    uuidShort() {
      return this.uuid.uuid4.substring(0,4);
    },
  },
  methods: {
    onSubmit() {
      this.$refs[this.COMPNAME].hide();
      this.$emit('submit');
    },
    onReset(ev) {
      this.$emit('reset', ev);
    }
  },
  components: {
    ModalFormGeneric,
    QrShow: () => import('@/components/QrShow'),
  },
}
</script>
